.slick-slider-wrapper {
  .slick-arrow {
    z-index: 1;
    height: 100%;
    width: 10vw;
    min-width: 50px;
    &.slick-prev {
      left: 0;
    }
    &.slick-next {
      right: 0;
    }
  }
}
