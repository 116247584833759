:root {
    --light: #f8f9fa !important;
}

.bg-light {
    background-color: var(--light) !important;
}

.App {
    height: 100vh;
    flex-grow: 1;
    max-width: 100%;
    display: flex;
}

.App #main-content {
    overflow-y: auto;
}

.flex-fill {
    flex-grow: 1;
    max-width: 100%;
    display: flex !important;
}

.btn.btn-xs {
    padding: .25rem .4rem;
    font-size: .575rem;
    line-height: 1.5;
    border-radius: .2rem;
}

.drawer {
    overflow: hidden;
    height: 100%;
}

.menu {
    height: 100%;
    overflow: hidden;
}

.menu > .menu-body {
    height: calc(100% - 60px);
    overflow: auto;
}

@media (max-width: 5000px) {
    .drawer {
        position: fixed;
        visibility: hidden;
        z-index: 9999;
        width: 100%;
        transition: opacity 0.28s ease-out, visibility 0.28s ease-out;
    }

    .cover {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.28s ease-out, visibility 0.28s ease-out;
    }

    .menu {
        position: absolute;
        width: 250px;
        top: 0;
        left: 0;
        transition: -webkit-transform 0.21s ease-out;
        transform: translateX(-100%);
    }

    .menu-show {
        transform: translateX(0%);
    }

    .drawer-show, .drawer-show > .cover {
        visibility: visible;
        opacity: 1;
    }
}

.drawer .drawer-header {
    /*background: #6c757d !important;*/
}

.drawer .menu-header {
    background-color: #495057;
}

.drawer .menu .menu-header h4 {
    color: #f8f9fa;
}

.clickable {
    cursor: pointer;
}

.drawer .menu .menu-body .cate-header.clickable:hover {
    color: #fff !important;
    background-color: #858585;
    border-color: #858585;
}

.sub-cate {
    overflow: hidden;
    height: auto;
    transition-duration: 234ms;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.sub-cate > .list-group-item {
    height: auto;
    transition-duration: 234ms;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    /*color: #6c757d;*/
    color: #dee2e6;
    font-weight: 100;
    background-color: transparent;
}

.sub-cate > .list-group-item:hover {
    color: #fff;
    background-color: #858585;
    border-color: #858585;
}

.sub-cate-hidden, .sub-cate-hidden > .list-group-item {
    height: 0;
    padding: 0;
}

.open-cate {
    transform: rotate(90deg);
}

.shadow-none {
    box-shadow: none !important;
}

.combobox .dropdown-menu {
    top: 100% !important;
    transform: none !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rbc-day-slot.rbc-today, .rbc-day-bg.rbc-today {
    background-color: transparent !important;
}

.rbc-time-slot {
    min-height: 30px;
}

.rbc-time-slot:hover {
    background-color: #ededed;
}

.nav-arrows .nav-link.active {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nav-arrows .nav-link.active::after {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    content: "\f078";
    display: block;
}

.navbar-brand:hover, .navbar-brand:focus {
    background-color: transparent;
}

.navbar-brand {
    pointer-events: auto;
}

.dropdown-transparent > button {
    background: transparent !important;
    box-shadow: none;
    border: none;
}

.dropdown-transparent > button:hover {
    text-decoration: underline;
}

.stripe-input {
    display: flex;
    align-items: center;
    border: none;
    box-shadow: none;
    background-color: #FAFAFA;
}

.stripe-input > .__PrivateStripeElement {
    width: 100%;
}

.banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.object-fit-contain {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.object-fit-cover {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.slick-slider div {
    position: relative;
    height: 100%;
}

.tittle {
    position: relative;
}

.tittle h2 {
    background: transparent;
    display: inline-block;
    position: relative;
    padding: 0 5%;
    z-index: 999;
    color: #282828;
    font-weight: 400;
}

.tittle::before {
    background: rgb(235, 235, 235) none repeat scroll 0 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 17px;
    width: 45%;
}

.tittle::after {
    background: rgb(235, 235, 235) none repeat scroll 0 0;
    content: "";
    height: 1px;
    right: 0;
    position: absolute;
    top: 17px;
    width: 45%;
}

.has-top {
    /* margin-top: 42px; */
}

.product-title {
    line-height: 1.5rem;
    height: 3rem;
    overflow: hidden;
}

.cart-badge {
    font-size: 50%;
    top: 20% !important;
    margin: -5px;
}

.btn-link:focus, .btn-link.focus {
    text-decoration: none;
}

body {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}